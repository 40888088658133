/* ContactUs.css */
.contact-us-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-form label {
    margin-bottom: 5px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 8px;
    margin-bottom: 10px;
  }
  
  .contact-form textarea {
    resize: none;
  }
  
  .contact-form button {
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .contact-form button:hover {
    background-color: #0056b3;
  }
  